.rootContent {
    background-image: url("https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatBg.png");
    background-size: cover;
    position: relative;
}
.colorClick {
    color: #3e578a;
    cursor: pointer;
}
div {
    white-space: pre-wrap;
}
#root {
    height: 100vh;
    overflow: auto;
    position: relative;
}
.choicesBlock {
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
    position: relative;
    width: 40%;
    text-align: center;
    margin: 2% 0;
}
.pageBanner {
    position: absolute;
    top: 0px;
    height: 100%;
    text-align: left;
    box-sizing: border-box;
    font-weight: lighter;
    line-height: 1.2;
    width: 65%;
    left: 10%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.NFTBanner {
    position: absolute;
    top: 0px;
    height: 100%;
    text-align: left;
    box-sizing: border-box;
    font-weight: lighter;
    line-height: 1.2;
    width: 50%;
    left: 50%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: #ffffff;
}
.NFTBannerItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    margin: 36px 0;
}
.NFTBannerItems {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 6px 0;
}
.NFTContent {
    background: #ffffff;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 36px;
}
.NFTItem {
    background: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 36px;
    border-radius: 24px;
    font-weight: bold;
    font-size: 24px;
    margin: 12px 36px;
}

.NFTItem img {
    height: 180px;
}
.NFTItemBlock {
    padding: 0 36px;
}
.NFTContentFont {
    color: #862FFE;
    padding-top: 12px;
    font-size: 20px;
}
.NFTQuestion {
    background: #1D1D27;
    padding: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.NFTQuestionItem {
    background: #3B3B47;
    border-radius: 24px;
    padding: 24px 48px;
    width: 80%;
    box-sizing: border-box;
    color: #ffffff;
    line-height: 30px;
    margin: 16px 0;
}
.NFTQuestionTitle {
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 8px;
}
.BannerFont {
    width: 60%;
}
.kasPage {
    background: #f5f9fd;
    min-height: 100vh;
}
.kasMiner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 36px;
}
.kasBlock {
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0 10px 20px 0 rgba(77, 124, 255, 0.03);
}
.kasTable {
    overflow: auto;
    background: #ffffff;
    padding-bottom: 12px;
}
.TableHead {
    width: 1300px;
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 35px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: nowrap;
    background: #83f;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
}
.TableContent {
    width: 1300px;
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 35px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: nowrap;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #f5faff;
    padding-top: 6px;
    padding-bottom: 6px;
}
.choicesImg {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 200px;
}
.topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    padding: 6px 10px 6px 25px;
    color: #ffffff;
    font-size: 14px;
    z-index: 4;
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    margin-left: -15px;
}
.mobileTap {
    background: #000000;
    height: 120px;
    z-index: 20;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    box-sizing: border-box;
}
.mobileList {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    flex-direction: column;
}
.mobileListBlock {
    width: 50%;
    background: linear-gradient(175deg, #ffffff, #dad2e1);
    border-radius: 48px 0 0 48px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 70px 40px;
    color: #1e1e1e;
    text-align: center;
    font-size: 48px;
    line-height: 72px;
    position: relative;
    right: -50%;
    transition-duration: 0.5s;
    letter-spacing: 2px;
}
.mobileIcon {
    width: 120px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentPage {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 72px 15%;
}
.flexBetween{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.colorClick:hover {
    color: #8833ff;
}
.displayFlex {
    display: flex;
    align-items: center;
}
.MineFont {
    margin-left: 13px;
    width: 40px;
    text-align: center;
    margin-right: 12px;
    font-size: 17px;
}
.minePage {
    margin: 16px -1%;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1
}
.mineTab {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 3px #ccc;
    margin: 24px 0px 24px 24px;
    border-radius: 36px;
    cursor: pointer;
}
.hashrateTab {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 3px #ccc;
    margin: 24px 24px 24px 0px;
    border-radius: 36px;
    cursor: pointer;
}
.positionRelative {
    position: relative;
}
.ALEOCard {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;
    background: #f8f8f8;
    width: 28%;
    margin: 8px;
}
.CardTop {
    background: #373744;
    color: #ffffff;
    padding: 16px 24px;
}
.ALEOCardContent {
    padding: 16px 24px;
    font-size: 18px;
    text-align: left;
    line-height: 30px;
}
.CardCircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 20px;
}
.HomeCardList {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-wrap: wrap;
    color: #ffffff;
    font-weight: bold;
    width: 98%;
    padding: 32px 1% 0px;
}
.HomeCard {
    width: 16%;
    text-align: center;
    margin: 30px 0px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    line-height: 0;
    background: #000000;
    display: flex;
    flex-direction: column;
}
.HomeCardContent {
    padding: 20px 20px 12px;
    font-size: 13px;
    text-align: left;
    line-height: 20px;
}
.CardImg {
    width: 100%;
    height: 180px;
    object-fit: cover;
}
.CardBtn {
    display: flex;
    align-items: center;
    background: #ef545a;
    padding: 12px;
    border-radius: 36px;
    cursor: pointer;
    opacity: 1;
    transition-duration: 0.5s;
    color: #ffffff;
    font-weight: bold;
}
.CardBtn:hover {
    opacity: 0.7;
}
.mineSelect {
    padding: 12px;
    border-radius: 24px;
    margin: 16px;
}
.languageBox {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: 16px;
    padding: 0 10px;
}
.languageBox:hover {
    background: #3d3d3d;
    border-radius: 20px;
}
.messageList {
    word-wrap: break-word;
}
.inviteBanner {
    background: #f5f3fe;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 4%;
    border-radius: 12px;
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
}
.inviteBanner2 {
    background: #f5f3fe;
    display: grid;
    grid-template-columns: repeat(3,auto);
    align-items: center;
    padding: 4%;
    border-radius: 12px;
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
}
.inviteTap {
    color: #ffffff;
    font-size: 14px;
    background: #b783ff;
    padding: 6px 12px;
    border-radius: 24px;
    display: inline-block;
}
.inviteBtn {
    margin-top: 24px;
    color: #ffffff;
    background: #8833ff;
    padding: 16px 24px;
    border-radius: 24px;
    box-shadow: 4px 2px 6px #f0d7fc
}

.navStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #ffffff;
    height: 70px;
    color: #565656;
    box-sizing: border-box;
    position: fixed;
    top: 42px;
    left: 0px;
    z-index: 2;
    margin-left: -15px;
    transition-duration: 0.5s;
}
.banner {
    width: 100%;
    min-height: 480px;
    object-fit: cover;
}
.bannerTop {
    margin-top: 40px;
}
.appLogin {
    height: 24px;
    cursor: pointer;
    margin-right: 6%;
}

.picFade {
    transition-duration: 0.5s;
    transform: scale(1);
}
.project {
    padding-top: 80px;
    min-height: 100vh;
    box-sizing: border-box;
}
.bottomItem {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    flex-direction: column;
}
.orderInfo {
    position: relative;
    background: rgb(247, 247, 247);
    padding: 16px 32px;
    border-radius: 16px;
    display: grid;
    grid-template-columns: repeat(3,auto);
}
.homeVideo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
    background-color: #000000;
    margin-top: -12px;
    line-height: 0;
}
.FILMine {
    height: 92vh;
    position: relative;
    min-height: 340px;
}
.flexCenter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}
.ArrowIcon {
    transition-duration: 0.5s;
    transform: rotate(0deg);
    height: 16px;
}
.bannerTitle {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
    margin-left: 10%;
    margin-top: 64px;
}
.homeTitle {
    width: 64%;
}
.margin1 {
    margin: 2% 0 4%;
}
.planDetail {
    display: grid;
    grid-template-columns: repeat(4,auto);
    margin: 0 -16px;
}
.DetailItem {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 16px;
}
.businessList {
    display: flex;
    flex-wrap: wrap;
}
.businessList span {
    width: 33.3%;
}
.scrollbar {
    flex: 1 1 0%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
/*.scrollbar::-webkit-scrollbar {*/
/*    width : 10px;*/
/*    height: 1px;*/
/*}*/
/*.scrollbar::-webkit-scrollbar-thumb {*/
/*    border-radius: 10px;*/
/*    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);*/
/*    background   : #f5f3fe;*/
/*}*/
/*.scrollbar::-webkit-scrollbar-track {*/
/*    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);*/
/*    border-radius: 10px;*/
/*    background   : #be8fff;*/
/*}*/
.buyItem {
    display: flex;
    align-items: center;
    /*width: 30%;*/
    justify-content: start;
    margin: 8px 0px;
}
.picFade:hover {
    transform: scale(1.2);
}
.rightTel {
    background: #ac4280;
    color: #ffffff;
    position: fixed;
    right: 0;
    z-index: 10;
    top: 40%;
    text-align: center;
    padding: 20px 12px;
    border-radius: 16px 0 0 16px;
    font-size: 16px;
}
.telBtn {
    background: #ffffff;
    color: #8f92a7;
    width: 90px;
    margin: auto;
    padding: 8px;
    border-radius: 16px;
    cursor: pointer;
    margin-top: 16px;
}
.gameIndex {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 12px;
    transition-duration: 0.5s;
    cursor: pointer;
}
.navBox{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    margin: 0 12px;
    position: relative;
    height: 40px;
    font-size: 16px;
    letter-spacing: 0px;
    cursor: pointer;
    flex-direction: column;
}
.noticeList {
    padding: 16px;
    border-radius: 16px;
}
.rateList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: bold;
}
.blockTag {
    width: 6px;
    height: 6px;
    background: #8833ff;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 2px;
}
.rateAmount {
    min-width: 45%;
    margin: 3% 2%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 12px 8px 4px;
    box-shadow: #e8e8e8 2px 0px 4px;
    border-radius: 12px;
}
.timeList {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: bold;
}
.timeAmount {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 24px;
    box-shadow: rgb(217 215 215) 2px 0px 4px;
    border-radius: 24px;
    margin: 0px 30px 30px 0;
    background: #ffffff;
    color: #f77e00;
    min-width: 100px;
}
.W3Wscrollbar {
    height: 100vh;
    background: #f7f8fa;
}
.scrollbar {
    flex: 1 1 0%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.navLeftTab {
    width: 240px;
    color: #1d1d27;
    background: #f5f3fe;
    z-index: 1;
    overflow: hidden;
    border-radius: 24px 0 0 24px;
    padding: 12px 0;
}
.rateCircle {
    width: 14px;
    height: 14px;
    margin: 0 4px 0 8px;
    border-radius: 50%;
    border: 2px solid #687898;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.positionAbsolute {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 24px;
    box-sizing: border-box;
    font-weight: lighter;
}
.flexColumn {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.navSelect{
    min-width: 100px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.navBox:hover .navShow{
    opacity: 1;
}
.navShadow .navShow{
    opacity: 1;
}
.orderCard {
    width: 30%;
    padding: 24px;
    margin: 0 3% 24px 0;
    border: 1px solid #cccccc;
    border-radius: 8px;
}
.navShow {
    width: 32px;
    background: #565656;
    height: 4px;
    position: relative;
    top: 6px;
    opacity: 0;
    transition-duration: 0.5s;
}
.scan {
    width: 400px;
}
.canClick {
    cursor: pointer;
    transition-duration: 0.5s;
    text-decoration: none;
}
.AmountClick {
    transition-duration: 0.5s;
    cursor: pointer;
    background: #FFF2D2;
}
.AmountClick:hover {
    background: #FFE3AC;
}
.stepBlock {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.stepList {
    position: relative;
    width: 600vw;
    height: 100%;
    transition-duration: 0.5s;
    display: flex;
    align-items: start;
    justify-content: flex-start;
}
.stepMobile {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.FontStep {
    font-size: 48px;
    padding-bottom: 36px;
}
.stepWeb {
    padding: 0 5% 2% 2.8%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96%;
}
.step {
    width: 15%;
    text-align: center;
    position: relative;
    z-index: 1;
    transition-duration: 0.5s;
    transform: scale(1);
    line-height: 0;
}
.step img {
    box-shadow: 0 0 0 #ffffff;
}
.step:hover {
    transform: scale(1.25);
    z-index: 12;
}
.step img:hover {
    box-shadow: 0px 2px 12px #2f2f2f;
}
.downloadPage {
    width: 60%;
    left: 10%;
    padding: 0px;
    top: 15%;
}
.downloadBtn {
    display: flex;
    align-items: center;
    background: rgb(37, 43, 43);
    color: rgb(255, 255, 255);
    padding: 10px 12px 12px;
    border-radius: 32px;
    min-width: 120px;
    margin-right: 24px;
    text-align: center;
}
.hoverShow {
    display: none;
    z-index: 3;
}
.hoverShow .list {
    background: #ffffff;
    color: #666666;
}
.hoverShow .list:hover{
    color: #ffffff;
    background: #000000;
}
.canClick:hover .hoverShow{
    display: initial;
}
.depositOpen {
    box-shadow: 0 0 4px #ccc;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 16px;
    width: 100px;
    margin-top: 50px;
    font-size: 16px;
}
.mineList {
    background: rgb(255, 255, 255);
    border-radius: 12px;
    padding: 16px;
    text-align: center;
    color: rgb(104, 120, 152);
    width: 28%;
    margin: 0 1% 24px;
    box-sizing: border-box;
}
.content {
    width: 85%;
    margin-left: 7.5%;
    margin-right: 7.5%;
    box-sizing: border-box;
    border-radius: 12px;
}
.notice {
    height: 100%;
    width: 100%;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.userTab {
    height: 56px;
    padding: 0px 24px;
    border: 1px solid rgb(204, 204, 204);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.loginBg {
    /*font-weight: bold;*/
    transition-duration: 0.5s;
    position: relative;
    background-size: 100% 100%;
    font-size: 20px;
    color: rgb(46, 51, 84);
    padding: 48px;
    width: 500px;
    margin-top: 80px;
    text-align: center;
    border-radius: 16px;
    background: #ffffff;
}
.leftTab {
    padding: 16px 10px;
    margin-top: 8px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.leftTab span {
    position: relative;
    bottom: 1px;
}
.leftTab:hover {
    /*color: #0a0b0d;*/
    background: #ffffff;
}
.question {
    background: #000000;
    color: #FFFFFF;
    padding: 8px;
    border-radius: 8px;
    font-size: 13px;
    min-width: 240px;
}
.questionArrow {
    width: 0px;
    height: 0px;
    border-width: 0 12px 12px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000000;
    transform: rotate(180deg);
    margin-left: 6px;
}
.tabSelect {
    /*color: #ffffff;*/
    background: #ffffff;
}
#googleCodeScan canvas{
    width: 100%;
    height: 100%;
}
#googleCodeScan img{
    width: 100%;
    height: 100%;
}
.AppInput {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-size: 13px;
    border-bottom: 1px solid #666666;
}
.loginBtn {
    font-weight: 600;
    color: #ffffff;
    background: #8833ff;
    width: 240px;
    margin: 40px auto 0;
    padding: 16px;
    border-radius: 56px;
    text-align: center;
    box-sizing: border-box;
}
.newHomeBtn {
    background: #8833ff;
    line-height: 1;
    padding: 12px 16px 16px;
    border-radius: 20px;
    color: #FFFFFF;
    z-index: 2;
}
.Page {

}
.inputStyle {
    color: #1d1d27;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    box-sizing: border-box;
    font-weight: bold;
    border: 0;
    background-color: transparent !important;
}
.contactInput {
    color: #1d1d27;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    background-color: transparent !important;
    border-radius: 8px;
    margin: 8px 0 16px;
}
.scanCode {
    width: 120px;
    height: 120px;
    margin: auto;
}
.scanCode canvas{
    opacity: 0;
}
.scanCode img {
    width: 100%;
    height: 100%;
}
.inviteCanvas {
    width: 306px;
}
.inviteCode {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 454px;
    left: 228px;
    opacity: 0;
}
.inviteCode img {
    width: 100%;
    height: 100%;
}
.stepFont {
    position: absolute;
    width: 100%;
    line-height: 1.2;
    top: -40px;
}
.userContent {
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 0px 16px 16px 0px;
    padding: 3%;
}
.userTitle {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 24px;
}
.userAssets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
    padding: 16px 32px 16px 16px;
    margin-bottom: 16px;
    cursor: pointer;
}
.mineTab img{
    height: 30px;
}
.mineTab .displayFlex {
    padding: 12px;
}
.textContent {
    width: 100%;
    color: #333333;
    background: #f8f8f8;
    padding: 36px;
    border-radius: 16px;
    box-sizing: border-box;
    margin: 16px 0px 80px;
    font-size: 16px;
    line-height: 24px;
}
.threeCard {
    padding: 32px 24px;
    margin: 72px auto;
    border-radius: 16px;
    background: #1d1d27;
    color: #ffffff;
    max-width: 920px;
    box-sizing: border-box;
}
@media screen and (min-width: 1800px) {
    .scaleOpen {
        transform: scale(1.2);
        transform-origin: center;
    }
    .navLeftTab {
        width: 300px;
    }
    .leftTab {
        font-size: 20px;
    }
    .choicesBlock {
        transform: scale(1.2);
        transform-origin: center;
    }
}
@media screen and (max-width: 1000px) {
    .downloadPage {
        left: 5%;
        width: 70%;
        top: 5%;
    }
    .downloadBanner {
        min-height: 620px;
    }
    .FILBanner {
        min-height: 470px;
    }
    .ALEOBanner {
        min-height: 550px;
    }
    .mineTab img{
        height: 48px;
    }
    .mineTab .displayFlex {
        padding: 20px;
    }
    .suanLiList {
        background: #ffffff;
        color: #1d1d27;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0 0 20px #ececec;
        width: 100%;
        font-size: 36px;
        margin-bottom: 32px;
        padding: 16px;
    }
    .mineTab {
        border-radius: 48px;
    }
    .MineFont {
        font-size: 36px;
        width: 100px;
    }
    .BannerFont {
        width: 80%;
    }
    .pageBanner {
        left: 7%;
        width: 65%;
    }
    .stepFont {
        font-size: 24px;
    }
    .downloadBtn {
        transform: scale(1.5);
        transform-origin: left;
        margin-right: 120px;
    }
    .minePage {
        margin: 24px;
    }
    .content {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        border-radius: 0px;
    }
    .ALEOCardContent {
        font-size: 24px;
    }
    .CardTop {
        font-size: 24px;
    }
    .textContent {
        font-size: 20px;
        line-height: 36px;
    }
    .serviceTest {
        font-size: 24px;
    }
    .HomeCardList {
        width: 85%;
        padding: 32px 7.5% 0px;
    }
    .HomeCardContent {
        padding: 20px;
    }
    .FILMine {
        height: 30vh;
        margin-top: 0 !important;
    }
    .CardImg {
        height: 230px;
    }
    .ALEOCard {
        width: 88%;
        margin: 16px 0;
    }
    .scan {
        width: 80%;
    }
    .mineList {
        width: 100%;
        margin: 0 0 24px 0;
    }
    .flexItem {
        width: 33.3%;
    }
    .appLogin {
        height: 16px;
        margin-right: 4%;
    }
    .navBox {
        font-size: 18px;
    }
    .languageBox {
        width: initial;
    }
    .positionAbsolute {
        font-size: 13px;
    }
}

@media screen and (max-width: 1000px) {
    .contentPage {
        margin: 100px 3% 3%;
    }
    .mobilePaddingTop {
        padding-top: 120px;
    }
    .homeTitle {
        width: 100%;
    }
    .homeVideo {
        margin-top: -60px;
    }
    .loginBtn {
        padding: 32px;
    }
    .webShow {
        display: none !important;
    }
    .margin1 {
        margin: 1% 0 1%;
    }

    .HomeCard {
        width: 46%;
        margin: 16px 0px;
    }
}

@media screen and (min-width: 1000px) {
    .mobileShow {
        display: none !important;
    }
}

@media screen and (orientation: portrait) {
    .FILBack {
        position: absolute;
        top: 20px;
        width: 80px;
        height: 80px;
        opacity: 1;
        z-index: 26;
        right: 20px;
    }
    .FILIFrame {
        position: fixed;
        width: 100vh;
        height: 100vw;
        transform: rotate(90deg) translateY(-100vw);
        transform-origin: left top;
        z-index: 25;
        top: 60px;
    }
    .FILBack img {
        width: 80px;
        height: 80px;
        transform: rotate(90deg);
    }
}

@media screen and (orientation: landscape) {
    .FILBack {
        position: absolute;
        top: 20px;
        width: 80px;
        height: 80px;
        opacity: 1;
        z-index: 26;
        left: 20px;
    }
    .FILIFrame {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 25;
        top: 60px;
    }
    .FILBack img {
        width: 40px;
        height: 40px;
    }
}